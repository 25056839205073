import React, { Component } from 'react';
import { MenuItems } from "./MenuItems";
import './Navbar.css'

class Navbar extends Component {
    state = { clicked: false, hovered: false, linkClick: false }

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }

    handleHover = () => {
        this.setState({hovered: !this.state.hovered})
    }

    handleClose = () => {
        this.setState({linkClick: !this.state.linkClick})
    }

    render() {
        return(
            <nav className='NavbarItems'>
                <h1 className={this.state.hovered ? "fa-bounce navbar-logo" : "navbar-logo"} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}><a href="./index.html">portfolio <i className= "fa-solid fa-terminal"></i></a></h1>
                
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url} onClick={this.handleClick}>
                                    {item.title}  
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        );
    }
}

export default Navbar