export const MenuItems = [
    // about item
    {
        title: 'about',
        url: '#about',
        cName: 'nav-links'
    },
    // projects item
    {
        title: 'projects',
        url: '#projects',
        cName: 'nav-links'
    },
    // contact item
    {
        title: 'contact',
        url: '#contact',
        cName: 'nav-links'
    },
    // github item
    {
        title: 'github',
        url: 'https://github.com/bradd07',
        cName: 'nav-links'
    },
]