import React, { Component } from 'react';
import './footer.css'

class Footer extends Component {
    state = { hovered: false }

    handleHover = () => {
        this.setState({hovered: !this.state.hovered})
    }
    
    render() {
        return(
            <div className="footer-container">
                <div id="footer">
                    <div>
                        <div className="icon">
                            <a href="https://github.com/bradd07" target="_blank" rel="noreferrer">
                                <i className={this.state.hovered ? "fa-brands fa-github fa-3x fa-beat-fade" : "fa-brands fa-github fa-3x"} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}></i>
                            </a>
                            
                            <a href="https://www.linkedin.com/in/bradley-essegian-90115523b/" target="_blank" rel="noreferrer">
                                <i className={this.state.hovered ? "fa-brands fa-linkedin fa-3x fa-beat-fade" : "fa-brands fa-linkedin fa-3x"} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}></i>
                            </a>
                        </div>
                        <p className="copyright">Copyright &copy; 2022 Bradley Essegian</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer