import React, { useEffect } from 'react';
import aos from 'aos';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import 'aos/dist/aos.css';
import './resources/master.css';
import './resources/pages.css';
import './resources/animations.css'
import Particles from './components/Particle/Particles';

function App() {
    useEffect(() => {
        aos.init({ duration: 2000, delay: 200 });
    }, []);

  return (
    <>
        <div id="nav">
            <Navbar />
        </div>

        <div id="content-container">
            <div className="pages">
                <div data-aos="fade-down" id="about">
                <Particles className="particles"/>
                    <div className="animate">
                        <h1>bradley essegian <span>&nbsp;</span></h1>
                    </div>
                    <h2 id="lang-icons"><i className="fa-brands fa-js"></i> <i className="fa-brands fa-java"></i><i className="fa-brands fa-react"></i> <i className="fa-brands fa-php"></i> <i className="fa-brands fa-python"></i> <i className="fa-solid fa-c"></i> <i className="fa-solid fa-database"></i> <br /><br />
                    
                    Java/Python/C/Front-end Developer & UI/UX Designer
                    </h2>
                </div>

                <div data-aos="fade-up" id="projects">
                    <h1>my projects</h1>
                </div>

                <div data-aos="fade-right" id="contact">
                    <h1>contact me</h1>
                </div>
            </div>
            <Footer />
        </div>
    </>
  );
}

export default App;
